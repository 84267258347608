@import '../../utils/styles.utils.scss';

.conexion_container {
    display: flex;
    background-color: $epic_black;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    column-gap: 4%;
    padding-block: 40px;

    .text_container {
        color: $epic_yellow;
        text-align: center;
        font-size: 40px;

        .marked_text {
            color: $epic_black;
            background-color: $epic_yellow;
            font-weight: 700;
        }
    }

    &__consultoria {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__bloque {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__imagen {
            padding: 10px;
        }
    }
}

.consultoria__texto {
    text-align: center;

    a {
        text-decoration: none;
        color: #FFF;

        &:hover {
                color: $epic_yellow;
            }
    }
}