@import '../../utils/styles.utils.scss';
.content-icon {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    &__left-content {
        display: flex;
        width: 50%;
        min-width: 400px;
        flex-direction: column;

        &.extra-width {
            width: 75% !important;
        }

        &__content-text {
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 50px;
            white-space: pre-line;

            h2 {
                font-size: 2rem;
            }

            p {
                font-size: 1.2rem;
            }
        }
    }

    &__right-content {
        width: 50%;
    }
}

.has-shadow {
    background-color: $epic-extra-ligth-gray;
    border-radius: 15px;
    padding: 30px;
}

@media only screen and (max-width: 800px) {
    .content-icon {
        &__left-content {
            width: 100%;
        }

        &__right-content {
            width: 100%;
        }
    }

}