@import '../../utils/styles.utils.scss';

.casos_exito {
    width: 100%;
    background-color: $epic-black;

    h2 {
        color: $epic-yellow;
        text-align: center;
    }

    .casos_container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        color: white;
        width: 100%;
        padding-block: 20px;

        .casos_container__casos_item {
            border: $epic-yellow 2px solid;
            border-radius: 15px;
            text-align: center;
            width: calc(100% / 3 - 100px);
            padding: 20px;

            h3 {
                color: $epic-yellow;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .casos_exito {
        .casos_container {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .casos_container__casos_item {
                width: 80%;
            }
        }
    }
}