@import '../../utils/styles.utils.scss';

.nosotros {
    &__titlecontainer {
        max-width: 500px;
        margin-top: -50px;
    }

    &__content {
        display: grid;
        grid-template-columns: 8fr 4fr;
        align-items: center;
        gap: 20px;
        border-radius: 15px;
        border: 1px solid $epic-yellow;
        padding: 20px 20px 10px 20px;
        margin: 50px;

        &__image {
            display: flex;
            justify-content: center;

            &>img {
                max-height: 450px;
                width: auto;
            }
        }
    }
}

.ecosistema {
    &__titlecontainer {
        max-width: 600px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 40px 20px;
        gap: 20px;

        &__item-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            .ecosistema__content__item-container__item {
                border-radius: 15px;
                padding: 20px;
                background-color: black;
                color: #FFF;

                &>.item_header {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: center;
                    justify-content: flex-start;
                    font-weight: 700;
                    padding-block: 10px;

                    &>.numero {
                        background-color: $epic-yellow;
                        color: black;
                        padding: 5px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.mision {
    background-color: black;
    color: $epic-yellow;
    padding: 30px;
    display: flex;
    justify-content: center;

    &__container {
        max-width: 1024px;

        &__title {
            padding: 10px;
            background-color: $epic-yellow;
            color: black;
            display: inline;
            font-size: 2rem;
            font-weight: 700;
        }

        &__text {
            padding: 10px;
            margin-top: 40px;
            font-size: 1.5rem;
        }
    }

}

.valores {
    &__titleContainer {
        max-width: 600px;
        margin-top: -30px;

    }

    &__content-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__content {
            max-width: 1024px;
            padding: 50px 20px 20px 20px;
            display: grid;
            gap: 30px;

            &__items-container {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;

                &__item {
                    padding: 20px;
                    border-radius: 25px;

                    &.bg-yellow {
                        background-color: $epic-yellow;
                    }

                    &.bg-black {
                        background-color: black;
                        color: #FFF;
                    }

                    &__title {
                        font-size: 1.2rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}