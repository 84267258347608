@import '../../utils/styles.utils.scss';

.productos-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 2rem;
    }

    p {
        font-size: 1.3rem;
        margin-bottom: 50px;
        padding-inline: 20px;
        color: $epic-black;
        text-align: center;
    }

    .categorias_container_mobile {
        display: none;
        padding: 20px;

        select {
            font-size: 1.1rem;
        }
    }

    .productos-content {
        display: grid;
        grid-template-columns: 1fr 2.5fr;
        padding-inline: 40px;

        .categories_container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            align-content: flex-start;
            justify-content: center;

            .category {
                cursor: pointer;
            }
        }

        .products_list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 15px;
            justify-content: center;

            .product {
                width: 150px;
                display: flex;
                justify-content: flex-end;
                text-align: center;
                flex-direction: column;
                cursor: pointer;
                text-decoration: none;
                transform: translateZ(0);
                transition: transform 0.25s ease-out;

                img {
                    padding-top: 20px;
                }

                .nombre_container {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .nombre_producto {
                        font-size: 1rem;
                        margin: 0;
                        padding: 10px;
                        background-color: $epic-yellow;
                        border-radius: 20px;
                    }
                }
            }

            .product:hover {
                transform: scale(1.05);
            }
        }
    }
}

.productosHeader {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .productosHeader_content {
        font-size: 1.2rem;
        padding-inline: 20px;
        text-align: center;
        color: #FFF;
        background-color: #08090E;
        margin: 55px;
        padding: 20px 30px;
        border-radius: 55px;
    }

    .wp-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-block-end: 30px;

        a {
            background-color: $epic-yellow;
            color: black;
            padding: 20px 50px;
            border-radius: 30px;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 2.5rem;
            }
        }
    }
}

.productos_title {
    background-color: $epic-yellow;
    color: $epic-black;
    font-size: 3rem;

    p {
        padding: 20px;
        margin: 0;
        line-height: 3rem;
    }
}

@media only screen and (max-width: 600px) {
    .productos-container {
        .categorias_container_mobile {
            display: block;
        }

        .productos-content {
            grid-template-columns: auto;

            .categories_container {
                display: none;
            }
        }
    }
}