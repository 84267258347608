@import '../../utils/styles.utils.scss';

.certificados-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    row-gap: 20px;
    background-color: $epic-black;
    position: relative;
    padding-block: 30px;

    &__bg-yellow {
        background-color: $epic-yellow;
        width: 95%;
        height: 250px;
        border-radius: 30px 0 0 30px;
        position: absolute;
        margin-top: 50px;
    }

    &__bg-white {
        background-color: white;
        width: 93%;
        height: 250px;
        border-radius: 30px 0 0 30px;
        z-index: 3;
    }

    & h2 {
        text-align: left;
        font-size: 2rem;
        color: $epic-black;
        padding-left: 100px;
    }

    &__certificaciones {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        justify-content: center;
        align-items: center;
        row-gap: 30px;
        padding: 0 30px;
        overflow: hidden;
    }

    img {
        width: 150px;
        height: auto;
    }
}