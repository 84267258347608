.contentimage-container {
    display: flex;

    &__image-container {
        display: flex;
        width: 50%;
    }

    &__text-content {
        width: 50%;
        text-align: right;
        padding: 30px;

        h2 {
            font-size: 2rem;
        }

        p {
            font-size: 1.2rem;
        }
    }
}

@media screen and (max-width: 800px) {
    .contentimage-container {
        flex-wrap: wrap;

        &__image-container {
            width: 100%;
        }

        &__text-content {
            width: 100%;
        }
    }
}