@import '../../utils/styles.utils.scss';

.homologacion-header {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    padding: 30px;

    .title {
        font-size: 3rem;
        font-weight: 700;

        a {
            text-decoration: none;
        }

        .title_clear {
            padding: 10px;
        }

        .title_dark {
            color: $epic-yellow;
            background-color: black;
            padding: 10px;
        }

        .contacto {
            display: flex;
            justify-content: flex-end;
            text-align: center;
            align-items: center;
            margin-block: 10px;
            gap: 10px;

            .whatsapp-container-footer {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $epic-black;
            }

            .homologacion_hablemos {
                display: flex;
                background-color: black;
                color: $epic-yellow;
                font-size: 1.2rem;
                font-weight: 400;
                padding: 20px;
                border-radius: 15px;
                -webkit-box-shadow: -3px 4px 0px 0px rgba(240, 199, 1, 1);
                -moz-box-shadow: -3px 4px 0px 0px rgba(240, 199, 1, 1);
                box-shadow: -3px 4px 0px 0px rgba(240, 199, 1, 1);
            }
        }
    }
}

.homologacion_casos-exito {

    &__header {
        display: flex;
        background-color: $epic-yellow;
        color: $epic-black;
        font-size: 3rem;
        font-weight: 700;
        padding: 30px;
    }

    .subestaciones {
        .titleComp {
            width: 40%;
        }

        &__content {
            display: flex;
            border: 1px solid $epic-yellow;
            border-radius: 15px;
            padding: 50px 20px 20px 20px;
            margin: 0 30px 30px 30px;

            .contenido {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                width: 100%;
                
                &__left {
                    display: flex;
                    background-color: $epic-yellow;
                    padding: 20px;
                    border-radius: 30px;
                    gap: 20px;

                    &__image {
                        margin-left: -30px;
                        width: 50px;
                    }
                }

                &__right {
                    padding: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .contenido {
        grid-template-columns: 1fr !important;
    }
}