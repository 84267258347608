@import '../../utils/styles.utils.scss';

.hablemos_container {
    background-color: $epic_yellow;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    column-gap: 4%;
    padding-block: 50px;
    flex-wrap: wrap;
    padding-inline: 50px;

    .text_container {
        color: $epic-black;
        font-size: 40px;
        font-weight: bold;
        max-width: 65%;
        padding-block: 10px;
    }

    .button_container {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 15px;
        text-decoration: none;
        min-width: 30%;
        padding-block: 10px;

        .whatsapp_icon {
            svg {
                width: 60px;
                height: auto;
            }
        }

        .contact_button {
            border-radius: 15px;
            background-color: $epic-black;
            padding: 16px 20px;
            color: $epic-yellow;
            font-size: 20px;
        }
    }
}