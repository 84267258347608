@import '../../utils/styles.utils.scss';

.sub_title-pagination {
    position: absolute;
    left: 35%;
    display: flex;

    span {
        padding: 5px;
        background-color: $epic-yellow;
    }
}