@import '../../utils/styles.utils.scss';

.economia-circular_container {
    width: 100%;
    padding-block: 50px;

    .content_container {
        display: flex;

        .header_content {
            width: 45%;

            .header_content-text {
                .title_container {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding-top: 40px;

                    .title {
                        position: absolute;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        background-color: $epic-yellow;
                        height: 30px;
                        padding-block: 10px;
                        font-size: 28px;
                        border-radius: 0 50px 50px 0;

                        span {
                            padding-left: 40px;
                        }
                    }

                    .title_icon {
                        position: absolute;
                        right: 0;
                        display: flex;
                        background-color: $epic-yellow;
                        width: 70px;
                        border-radius: 50%;
                    }
                }

                .sub_title {
                    padding: 20px 50px;

                    .sub_title-text {
                        font-size: 30px;
                        font-weight: bold;
                        padding-bottom: 15px;
                    }

                    .sub_title-pagination {
                        position: absolute;
                        left: 35%;
                        display: flex;

                        span {
                            padding: 5px;
                            background-color: $epic-yellow;
                        }
                    }
                }
            }
        }

        .content_box {
            display: flex;
            flex-direction: column;
            width: 55%;
            margin-left: -40px;
            overflow-y: auto;
            height: 264px;
            scroll-snap-type: y mandatory;

            .content_item {
                display: flex;
                align-items: center;
                gap: 20px;
                background-color: $epic-extra-ligth-gray;
                border-radius: 15px;
                padding: 30px;
                height: 264px;
                scroll-snap-align: start;
                scroll-snap-stop: always;
            }
        }
    }

}

@media only screen and (max-width: 800px) {
    .economia-circular_container {
        .content_container {
            flex-direction: column;

            .header_content {
                width: 100% !important;

                .header_content-text {
                    .title_container {
                        .title {
                            span {
                                padding-left: 20px;
                                font-size: 30px;
                            }

                        }
                    }
                }

                .sub_title {
                    .sub_title-pagination {
                        left: 80% !important;
                    }
                }
            }

            .content_box {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-left: 0;
                height: 264px;

                .content_item {
                    display: flex !important;
                    flex-direction: column !important;
                    justify-content: center !important;
                    text-align: center !important;
                    align-items: center !important;
                    width: 100% !important;
                    height: 264px !important;
                    padding: 20px 0;

                    .img_content {
                        padding: 10px 30px 0 30px;

                        img {
                            height: 100px !important;
                            width: auto !important;
                        }
                    }

                    .text_content {
                        padding: 0 30px 10px 30px;
                    }
                }
            }
        }

    }
}