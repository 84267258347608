@import '../../utils/styles.utils.scss';

.footer-container {
    background-color: #08090E;
    color: white;
    bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: column;

    .foter-sections {
        display: flex;
        flex-direction: row;
        column-gap: 100px;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        margin-inline: 20px;

        .footer-section {
            display: flex;
            flex-direction: column;

            .logo-footer {
                width: 150px;
                height: auto;
                margin-top: 20px;
            }

            .footer-links {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                margin-block: 20px;

                .link-item,
                a {
                    color: white;
                    text-decoration: none;
                    font-size: 1.1rem;
                }

                .footer-whatsapp {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .whatsapp-container-footer {
                        bottom: -5px;
                        background: $epic-yellow;
                        border-radius: 20px;
                        width: 100%;
                        height: 70px;
                        display: flex;

                        justify-content: center;
                        align-items: center;

                        svg {
                            color: $epic-black;
                            font-size: 50px;
                        }
                    }
                }

            }
        }
    }

    .legal-p {
        text-align: center;
    }
}