@import '../../utils/styles.utils.scss';

.navigation-container {
    background-color: $epic-yellow;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;

    .logo_container {
        display: flex;
        align-items: center;
        justify-content: center;

        .page-logo>img {
            width: 150px;
            height: auto;
        }
    }


    .links-container {
        display: flex;
        column-gap: 10px;
        justify-content: space-between;
        align-items: center;
        width: 70%;

        .link-item {
            color: $epic-dark-gray;
            text-decoration: none;
            font-size: calc(15px + 0.390625vw);
            font-family: "Roboto", sans-serif;
        }

        .link-item:hover,
        .link-item:active,
        .link-item:focus {
            color: $epic-ligth-gray;
        }

        .header-whatsapp-container {
            background: #000;
            border-radius: 20px;
            width: 130px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                color: $epic-yellow;
                font-size: 50px;
            }
        }
    }

    .mobile-links-container {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 15px;
        font-size: 1.4rem;
        margin-top: 20px;

        a {
            text-decoration: none;
            color: $epic-dark-gray;
        }

        .header-whatsapp-container {
            background: #000;
            border-radius: 20px;
            width: 130px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                color: $epic-yellow;
                font-size: 50px;
            }
        }
    }

    .toggle_menu {
        cursor: pointer;
    }

    .arrow_menu,
    .close_menu {
        display: none;
    }

    .hide_element {
        display: none !important;
    }

}

@media only screen and (max-width: 800px) {
    .navigation-container {
        display: flex;
        flex-direction: column;

        .mobile-links-container {
            display: flex;
        }

        .arrow_menu,
        .close_menu {
            display: block;
        }

        .links-container {
            display: none;
        }

        .openmenu-container {
            display: block;
            cursor: pointer;
        }
    }
}