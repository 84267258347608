@import '../../utils/styles.utils.scss';

.icon-title-bar-container {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 40px;
    width: 100%;

    &__title {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        height: 30px;
        padding-block: 10px;
        font-size: 30px;
        border-radius: 0 50px 50px 0;
        
        &.clear{
            background-color: $epic-yellow;
            color: $epic-black;
        }

        &.dark{
            background-color: $epic-black;
            color: $epic-yellow;
        }

        span {
            padding-left: 40px;
            font-size: 28px;
        }
    }

    &__icon {
        position: absolute;
        right: 0;
        display: flex;
        width: 70px;
        border-radius: 50%;

        &.dark {
            background-color: $epic-black;
            color: $epic-yellow;
        }

        &.clear {
            background-color: $epic-yellow;
            color: $epic-black;
        }
    }
}